'use client'

import { Fragment, memo } from 'react'
import { t } from 'src/helpers/translate.helper'
import { Form } from './Form'

export const EmailModal = memo(function EmailModal() {
  return (
    <Fragment>
      <div className="mt-1 text-left text-secondary">
        {t(
          'Need to set up email for your profile for receive check about payment',
        )}
      </div>

      <div className="grid gap-x-2 mt-6">
        <Form />
      </div>
    </Fragment>
  )
})

export default EmailModal
