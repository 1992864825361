'use client'

import clsx from 'clsx'
import { signIn } from 'next-auth/react'
import { Fragment, useCallback, useState } from 'react'
import { Icon } from 'src/components/common/Icon'
import { EnAuthView } from 'src/enums/auth-view.enum'
import { t } from 'src/helpers/translate.helper'
import { Error } from '../../common/Error'

export default function Auth(props: {
  error?: string
  callbackUrl?: string
  className?: string
}) {
  const [view, setView] = useState(EnAuthView.SING_IN)

  const items = [
    {
      id: 'vk',
      color: 'bg-[#3b6298]',
      icon: <Icon name="vk" className="!w-6" />,
      title: t('throw vk'),
    },
    {
      id: 'google',
      color: 'bg-[#cc342b]',
      icon: <Icon name="google" className="!w-6" />,
      title: t('throw google'),
    },
  ]

  const getBlockTitle = useCallback(() => {
    switch (view) {
      case EnAuthView.REGISTER: {
        return t('Register')
      }

      case EnAuthView.SING_IN:
      default: {
        return t('Signin in account')
      }
    }
  }, [view])

  return (
    <div className={clsx(props.className)}>
      <h3 className="text-h4 leading-6">{getBlockTitle()}</h3>
      {props.error && (
        <Error
          className="mt-3"
          message={
            <Fragment>
              {props.error}.{' '}
              {t('Tell about error in channel https://t.me/topmangachat/4')}
            </Fragment>
          }
        />
      )}
      <div className="grid mt-8 w-full gap-y-4">
        {items.map((item) => (
          <div
            key={item.id}
            onClick={() => signIn(item.id, { callbackUrl: props.callbackUrl })}
            className="grid gap-x-5 grid-flow-col ring-1 dark:ring-black-700/50 ring-gray-200/50 justify-start items-center cursor-pointer dark:bg-black-600 bg-gray-200/50 hover:bg-gray-200 hover:dark:bg-black-500 rounded-l-full rounded-r-full"
          >
            <div
              className={clsx(
                'pl-5 pr-3 py-3 rounded-l-full text-white dark:text-black-700',
                item.color,
              )}
            >
              {item.icon}
            </div>
            <span className="uppercase text-tiny leading-3">{item.title}</span>
          </div>
        ))}

        <div className="grid mt-2 gap-y-1 text-center">
          <span className="text-tiny font-medium text-secondary dark:text-gray-250">
            {view === EnAuthView.SING_IN
              ? t("Don't have account?")
              : t('Do you have account?')}
          </span>

          <span
            className="text-primary hover:text-hover cursor-pointer"
            onClick={() => {
              setView(
                view === EnAuthView.SING_IN
                  ? EnAuthView.REGISTER
                  : EnAuthView.SING_IN,
              )
            }}
          >
            {view === EnAuthView.SING_IN ? t('Register') : t('Login')}
          </span>
        </div>
      </div>
    </div>
  )
}
