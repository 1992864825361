'use client'

import { usePathname } from 'next/navigation'
import { memo } from 'react'
import Auth from 'src/app/ui/auth/signin'

export const AuthModal = memo(function AuthModal() {
  const pathname = usePathname()

  return <Auth className="-mt-5" callbackUrl={pathname || undefined} />
})

export default AuthModal
