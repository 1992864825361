'use client'

import { User } from '@prisma/client'
import { usePathname } from 'next/navigation'
import { memo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { serverFetch } from 'src/app/lib/serverFetch'
import { addToast, handleExceptionRequest } from 'src/app/lib/utils/toast.util'
import { EnMethod, EnToastType } from 'src/enums'

import { EnComplainEntity } from 'src/enums/complain-entity.enum'
import { ComplainTypeEnum, EnComplainType } from 'src/enums/complain-type.enum'
import { useYupValidationResolver } from 'src/helpers/client/client.helper'
import { sleep } from 'src/helpers/main.helper'
import { t } from 'src/helpers/translate.helper'
import yup from 'src/instances/yup'
import { Button } from '../../common/Form/Button'
import { Select } from '../../common/Form/Select'
import { Textarea } from '../../common/Form/Textarea'

export type FormType = {
  type: EnComplainType
  comment: string | undefined
}

const requiredTypes: EnComplainType[] = [
  EnComplainType.DUPLICATE_CHAPTER,
  EnComplainType.WRONG_PAGE_INDEX,
]

const schema = yup.object().shape({
  type: yup
    .mixed()
    .oneOf(Object.keys(EnComplainType).map((value) => value))
    .required(),
  comment: yup
    .string()
    .nullable()
    .when(['type'], {
      is: (type: EnComplainType) => requiredTypes.includes(type),
      then: (schema) => schema.required(),
    }),
})

const getChapterId = (pathname: string) => {
  const regex: RegExp = /\/ch(\d+)/
  const match = pathname?.match(regex)

  if (!match) {
    return null
  }

  return +match[0].replace('/ch', '')
}

export const ComplainModal = memo(function ComplainModal(props: {
  onClose: () => void
}) {
  const pathname = usePathname()
  const chapterId = pathname ? getChapterId(pathname) : null

  const resolver = useYupValidationResolver(schema)
  const {
    control,
    handleSubmit,
    trigger,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormType>({
    resolver,
    mode: 'all',

    defaultValues: {
      type: undefined,
      comment: undefined,
    },
  })

  const type = watch('type')
  const getPlaceholder = () => {
    switch (type) {
      case EnComplainType.WRONG_PAGE_INDEX: {
        return t('Fill up the right index')
      }

      case EnComplainType.DUPLICATE_CHAPTER: {
        return t('Pls, fill up the duplicate page')
      }

      default: {
        return undefined
      }
    }
  }

  const validateFields = async () => {
    await sleep(200)
    trigger()
  }

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    return serverFetch<User>({
      url: '/complains',
      params: {
        method: EnMethod.POST,
        body: JSON.stringify({
          ...data,
          entityId: chapterId,
          entity: EnComplainEntity.CHAPTER,
        }),
      },
    })
      .then(async () => {
        addToast(t('Complain successful send'), EnToastType.SUCCESS)
        props.onClose()
      })
      .catch(handleExceptionRequest)
  }

  return (
    <form className="text-left" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-y-1">
        <Select<FormType>
          control={control}
          name="type"
          loadOptions={() =>
            Promise.resolve(
              (Object.keys(EnComplainType) as EnComplainType[]).map(
                (reason, key) => ({
                  label: t(ComplainTypeEnum.getLabels()[reason]),
                  value: reason,
                  key,
                }),
              ),
            )
          }
          onChange={() => {
            validateFields()
          }}
          placeholder={t('select.type')}
          title={t('complain.type')}
        />

        <Textarea<FormType>
          placeholder={getPlaceholder()}
          control={control}
          name="comment"
          title={t('complain.comment')}
        />
      </div>

      <div className="grid grid-flow-col gap-x-2 gap-3 justify-start">
        <Button
          title={t('Send')}
          className="mt-2"
          isSubmitting={isSubmitting}
          errors={errors}
        />
      </div>
    </form>
  )
})

export default ComplainModal
