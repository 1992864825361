'use client'

import { memo, useCallback, useContext, useEffect, useState } from 'react'
import { Noop, RefCallBack } from 'react-hook-form'
import ReactSelect, {
  MultiValue,
  SingleValue,
  createFilter,
} from 'react-select'
import { Icon } from 'src/components/common/Icon'
import { ThemeContext } from 'src/components/providers/ThemeProvider'
import { EnTheme } from 'src/enums'
import { t } from 'src/helpers/translate.helper'
import { OptionType } from './types'

const Select2 = (props: {
  id: string
  value: string | string[] | null
  isMulti?: boolean
  isClearable?: boolean
  isDisabled?: boolean
  minLength?: number
  placeholder?: string
  isSuccess?: boolean
  isError?: boolean
  isFilter?: boolean
  isOpen?: boolean
  isSearchable?: boolean
  refInstance?: RefCallBack

  loadOptions?: () => Promise<OptionType[]>
  onChange: (value: string | string[] | null) => void
  onBlur?: Noop
}) => {
  const { theme } = useContext(ThemeContext)
  const isDarkMode = theme === EnTheme.DARK
  const [isLoadOptions, setIsLoadOptions] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(props.isOpen)

  const {
    isMulti = false,
    isClearable = false,
    isDisabled = false,
    minLength = 0,
    isFilter = false,
    value: initialValue,
  } = props

  const [inputValue, setInputValue] = useState('')
  const [selectedValue, setSelectedValue] = useState<
    MultiValue<OptionType> | SingleValue<OptionType>
  >([])
  const [options, setOptions] = useState<OptionType[]>([])

  const [error, setError] = useState<null | string>(null)

  const loadOptions = useCallback(
    async (value: string | string[] | null) => {
      const getOptions = props.loadOptions

      setIsLoading(true)

      getOptions &&
        getOptions()
          .then((items) => {
            setOptions(items)

            if (value) {
              setSelectedValue(
                items.filter((item) => {
                  if (Array.isArray(value)) {
                    return value.includes(String(item.value))
                  }
                  return String(value) === String(item.value)
                }),
              )
            }
          })
          .catch((errorMessage: string) => {
            setError(errorMessage)
          })
          .finally(() => {
            setIsLoading(false)
            setIsLoadOptions(true)
          })
    },
    [props.loadOptions],
  )

  useEffect(() => {
    !isFilter &&
      setSelectedValue(
        options.filter((item) => {
          if (Array.isArray(initialValue)) {
            return initialValue.includes(String(item.value))
          }
          return String(initialValue) === String(item.value)
        }),
      )
  }, [initialValue, options, isFilter])

  useEffect(() => {
    if (isLoadOptions) {
      return
    }

    const loadOptionsFunc = props.loadOptions

    if (!initialValue) {
      setSelectedValue([])
      return
    }

    if (loadOptionsFunc) {
      loadOptions(initialValue)
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, isLoadOptions])

  const onInputChange = (value: string) => {
    setInputValue(value)
  }

  const onChange = (
    values: MultiValue<OptionType> | SingleValue<OptionType>,
  ) => {
    setSelectedValue(values)
    setMenuIsOpen(undefined)

    if (Array.isArray(values)) {
      return props.onChange(
        (values as OptionType[]).map((value) => value.value),
      )
    } else {
      const value = values as OptionType | null
      return props.onChange(value ? value.value : null)
    }
  }

  return (
    <ReactSelect
      id={props.id}
      autoFocus={props.isOpen ?? undefined}
      ref={props.refInstance ?? undefined}
      instanceId={props.id}
      // menuPosition="fixed"
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          fontWeight: '400',
          background: state.isDisabled
            ? isDarkMode
              ? 'rgb(var(--color-light))'
              : 'rgb(209 213 219)' // gray-300
            : 'rgb(var(--color-form))',
          borderColor: state.isFocused
            ? 'rgb(var(--color-primary) / 0.85) !important'
            : props.isSuccess || props.isError
            ? props.isSuccess
              ? 'rgb(var(--color-success)) !important'
              : 'rgb(var(--color-danger)) !important'
            : isDarkMode
            ? 'rgb(38 38 38 / 0.5)'
            : 'rgb(229 231 235 / 0.5)',
          boxShadow: state.isFocused
            ? '0 0 0 1px rgb(var(--color-primary) / 0.85)'
            : 'none',
          opacity: state.isDisabled ? 0.5 : 1,
        }),
        dropdownIndicator: (baseStyles, state) => ({
          ...baseStyles,
          color: state.isFocused
            ? 'rgb(var(--color-primary)/ 0.85)'
            : 'rgb(var(--color-muted) / 0.5)',
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          background: 'rgb(var(--color-muted) / 0.5)',
        }),
        placeholder: (baseStyles) => ({
          ...baseStyles,
          textAlign: 'left',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }),
        valueContainer: (baseStyles, state) => ({
          ...baseStyles,
          paddingRight: '0.25rem',
          paddingLeft:
            state.isMulti && state.getValue().length ? '0.25rem' : '0.75rem',
        }),
        input: (baseStyles) => ({
          ...baseStyles,
          boxShadow: 'none',
          border: 'none',
          color: 'rgb(var(--color-muted))',
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          overflow: 'hidden',
          background: 'rgb(var(--color-form))',
        }),
        menuList: (baseStyles) => ({
          ...baseStyles,
          overflowX: 'hidden',
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          textAlign: 'left',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          background:
            state.isSelected || state.isFocused
              ? 'rgb(var(--color-form-hover))'
              : 'none',

          color:
            state.isSelected || (state.isMulti && state.isFocused)
              ? 'rgb(var(--color-primary)/ 0.85)'
              : isDarkMode
              ? 'rgb(var(--color-text) / 0.8)'
              : 'rgb(var(--color-text))',
        }),
        multiValue: (baseStyles) => ({
          ...baseStyles,
          background: isDarkMode ? 'rgb(38 38 38)' : 'rgb(209 213 219)', // gray-300
        }),
        multiValueLabel: (baseStyles) => ({
          ...baseStyles,
          color: isDarkMode
            ? 'rgb(var(--color-text) / 0.8)'
            : 'rgb(var(--color-text))',
        }),
        multiValueRemove: (baseStyles, state) => ({
          ...baseStyles,
          background: state.isFocused
            ? 'rgb(var(--color-primary) / 0.85)'
            : 'inherit',
          transitionDuration: '300ms',
          transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
          transitionProperty: 'all',
        }),
        noOptionsMessage: (baseStyles) => ({
          ...baseStyles,
          textAlign: 'left',
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          textAlign: 'left',
          color: isDarkMode
            ? 'rgb(var(--color-text) / 0.8)'
            : 'rgb(var(--color-text))',
        }),
      }}
      isSearchable={props.isSearchable}
      menuIsOpen={menuIsOpen}
      inputValue={inputValue}
      value={selectedValue}
      isMulti={isMulti}
      isDisabled={isDisabled}
      isClearable={isClearable}
      options={inputValue.length >= minLength ? options : []}
      isLoading={isLoading}
      placeholder={props.placeholder ?? t('Choose value')}
      noOptionsMessage={() =>
        error ??
        (inputValue.length >= minLength
          ? t("Don't have results")
          : t('Fill up more then {number} symbols', {
              number: minLength,
            }))
      }
      loadingMessage={() => <Icon className="w-4" name="loader" />}
      filterOption={createFilter({
        matchFrom: 'any',
        stringify: (option) => `${option.label}`,
      })}
      onBlur={props.onBlur}
      onFocus={() =>
        props.loadOptions && !isLoadOptions ? loadOptions(initialValue) : {}
      }
      onChange={onChange}
      onInputChange={onInputChange}
    />
  )
}

export default memo(Select2) as typeof Select2
