import io, { Socket } from 'socket.io-client'

const globalForSocket = globalThis as unknown as {
  socket: Socket | undefined
}

const SocketSingleton = (token?: string) => {
  return io(process.env.NEXT_PUBLIC_ENV_BACKEND_SOCKET, {
    path: '/admin-socket',
    transportOptions: token
      ? {
          polling: {
            extraHeaders: {
              Authorization: `Bearer ${token}`,
            },
          },
        }
      : {},
  })
}

export const getAdminSocket = (accessToken?: string) => {
  if (globalForSocket.socket) {
    return globalForSocket.socket
  }

  const socket = SocketSingleton(accessToken)
  globalForSocket.socket = socket

  return socket
}
