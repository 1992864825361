'use client'

import { usePathname } from 'next/navigation'

const LayoutWrapper = (props: { children: React.ReactNode }) => {
  const pathname = usePathname()

  if (pathname === '/') {
    return props.children
  }

  if (['/user/notifications'].includes(pathname ?? '')) {
    return (
      <div className="content-block h-full grid m-auto md:max-w-7xl w-full">
        {props.children}
      </div>
    )
  }

  return (
    <div className="content-block h-full grid m-auto md:max-w-7xl w-full md:px-4">
      {props.children}
    </div>
  )
}

export default LayoutWrapper
