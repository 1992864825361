import { FieldErrors } from 'react-hook-form'
import { t } from 'src/helpers/translate.helper'
import { Button as UIButton } from '../../Button'

export const Button = <T extends object>(props: {
  errors: FieldErrors<T>
  title?: string
  withLabel?: boolean
  type?: 'submit' | 'button'
  isSubmitting: boolean
  className?: string

  onClick?: () => void
}) => {
  const { title = t('Save'), withLabel = true } = props

  return (
    <UIButton
      className={props.className}
      {...(Object.keys(props.errors).length
        ? { disabled: true, color: 'danger', iconType: 'danger' }
        : { color: 'primary' })}
      {...(props.isSubmitting ? { isLoading: true, disabled: true } : {})}
      title={title}
      type={props.type}
      withLabel={withLabel}
      onClick={props.onClick}
    />
  )
}
