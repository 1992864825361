import { BaseEnum } from './BaseEnum'

export enum EnUserSetting {
  IS_ADULT = 'isAdult',
}

export class UserSettingEnum extends BaseEnum<EnUserSetting> {
  static getLabels() {
    return {
      [EnUserSetting.IS_ADULT]: 'Adult',
    }
  }
}
