import clsx from 'clsx'

export const Label = (props: {
  className?: string
  title: string
  name: string
}) => {
  return (
    <label
      className={clsx(
        'font-medium text-md text-gray-primary mb-0.5 justify-self-start cursor-pointer',
        props.className,
      )}
      htmlFor={props.name}
    >
      {props.title}
    </label>
  )
}
