'use client'

import { yupResolver } from '@hookform/resolvers/yup'
import { clsx } from 'clsx'
import { useSession } from 'next-auth/react'
import { memo, useContext, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { serverFetch } from 'src/app/lib/serverFetch'
import { addToast, handleExceptionRequest } from 'src/app/lib/utils/toast.util'
import { Icon } from 'src/components/common/Icon'
import { ModalContext } from 'src/components/providers/ModalProvider'
import { EnMethod, EnToastType } from 'src/enums'
import { t } from 'src/helpers/translate.helper'
import { checkEmailExist } from 'src/helpers/validate.helper'
import * as yup from 'yup'

type FormType = {
  email: string
}

const validationSchema = yup.object({
  email: yup
    .string()
    .required(t('This field is required'))
    .email(t('Entered value does not match email format'))
    .test(
      'isUniqueEmail',
      t('User with with email already exits'),
      async (value) =>
        new Promise<boolean>((resolve) => checkEmailExist(resolve, value)),
    ),
})

export const Form = memo(function Form() {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormType>({ resolver: yupResolver(validationSchema) })
  const { data: session, update } = useSession()
  const [isFetching, setIsFetching] = useState(false)
  const { toggleModal } = useContext(ModalContext)

  const onSubmit: SubmitHandler<FormType> = (data) => {
    if (!session) {
      return
    }

    setIsFetching(true)

    serverFetch({
      url: `/users/${session.user.id}`,
      params: {
        method: EnMethod.PATCH,
        body: JSON.stringify({
          email: data.email,
        }),
      },
    })
      .then(() => {
        addToast(t('Email successfull update'), EnToastType.SUCCESS)
        toggleModal(null)

        update({
          ...session,
          user: {
            ...session.user,
            email: data.email,
          },
        })
      })
      .catch((error) => {
        handleExceptionRequest(error)
        setIsFetching(false)
      })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid mt-3 gap-y-3 md:mt-1.5"
    >
      <div className="grid">
        <input
          placeholder="Email"
          className={clsx(
            'h-10 rounded dark:bg-[#101010] bg-[#f3f4f6] dark:border-[#3a3a3a] border-[#e5e7eb]',
            {
              '!border-red-500': errors.email,
            },
          )}
          {...register('email')}
        />
        <div className="h-5 leading-3 text-left">
          {errors.email && (
            <span className="text-red-500 text-xs">{errors.email.message}</span>
          )}
        </div>
      </div>

      <button
        disabled={isFetching}
        className="h-10 mx-auto w-40 border-2 disabled:opacity-50 border-primary rounded-md text-primary hover:text-black-1000 hover:bg-primary"
        type="submit"
      >
        {isFetching && <Icon name="loader" className="!text-danger w-4" />}
        {!isFetching && t('Save')}
      </button>
    </form>
  )
})
