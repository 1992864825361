import clsx from 'clsx'
import { ReactNode, memo } from 'react'
import { t } from 'src/helpers/translate.helper'

export const Error = memo(function Error(props: {
  message?: string | ReactNode
  className?: string
}) {
  const { message, className } = props

  return (
    <div
      className={clsx(
        'grid overflow-hidden dark:text-black-1000 rounded-sm2 py-0.5 ring-1 px-3 ring-red-600/50 bg-red-500',
        className,
      )}
    >
      {message ?? t('Error happend')}
    </div>
  )
})
