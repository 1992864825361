import clsx from 'clsx'
import { ReactNode, useRef } from 'react'
import { Control, Controller, Path } from 'react-hook-form'
import { Error } from '../Error'
import { Hint } from '../Hint'
import { Label } from '../Label'

export const Textarea = <T extends Record<string, any>>(props: {
  control: Control<T, any>
  name: Path<T>
  title?: string
  hint?: string | ReactNode
  placeholder?: string
  isDisabled?: boolean
  isDisabledRing?: boolean
  className?: string
  rows?: number
  autoFocus?: boolean
}) => {
  const ref = useRef<HTMLTextAreaElement>(null)

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, formState: { errors, touchedFields } }) => {
        const isError = errors[props.name] !== undefined
        const isSuccess = isError ? false : touchedFields[props.name]
        const error = errors[props.name]
          ? String(errors[props.name]?.message)
          : ''

        return (
          <div className="grid grid-flow-row">
            {props.title && (
              <Label
                className={clsx('', {
                  '!text-success': isSuccess,
                  '!text-danger': isError,
                })}
                name={props.name}
                title={props.title}
              />
            )}

            <textarea
              autoFocus={props.autoFocus}
              id={props.name}
              placeholder={props.placeholder}
              rows={props.rows ?? 5}
              className={clsx(
                'bg-form outline-none focus:!ring-primary focus:!border-primary rounded-sm2 dark:border-black-600/50 border-gray-200/50',
                props.className,
                {
                  '!border-danger !ring-danger':
                    isError && !props.isDisabledRing,
                  '!border-success !ring-success':
                    isSuccess && !props.isDisabledRing,
                  'opacity-50 !bg-gray-300 dark:!bg-form': props.isDisabled,
                },
              )}
              {...field}
              {...(props.isDisabled ? { disabled: true } : {})}
            />

            <div className="h-5 mt-0.5 grid">
              {error && <Error className="text-xs" message={error} />}
              {!error && <Hint message={props.hint} />}
            </div>

            {!error}
          </div>
        )
      }}
    />
  )
}
