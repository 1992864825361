import { EnCommentComplainReason } from '@prisma/client'
import { BaseEnum } from './BaseEnum'

export class CommentComplainReasonEnum extends BaseEnum<EnCommentComplainReason> {
  static getLabels() {
    return {
      [EnCommentComplainReason.FLUD]: 'EnCommentComplainReason.FLUD',
      [EnCommentComplainReason.OFFENSE]: 'EnCommentComplainReason.OFFENSE',
      [EnCommentComplainReason.SPAM]: 'EnCommentComplainReason.SPAM',
      [EnCommentComplainReason.SPOILER]: 'EnCommentComplainReason.SPOILER',
      [EnCommentComplainReason.SWEARING]: 'EnCommentComplainReason.SWEARING',
      [EnCommentComplainReason.WITHOUT_SENSE]:
        'EnCommentComplainReason.WITHOUT_SENSE',
      [EnCommentComplainReason.ANOTHER]: 'EnCommentComplainReason.ANOTHER',
    }
  }
}
