import { BaseEnum } from './BaseEnum'

export enum EnComplainType {
  DUPLICATE_CHAPTER = 'DUPLICATE_CHAPTER',
  LOST_CHAPTER_CONTENT = 'LOST_CHAPTER_CONTENT',
  WRONG_PAGE_INDEX = 'WRONG_PAGE_INDEX',
}

export class ComplainTypeEnum extends BaseEnum<EnComplainType> {
  static getLabels() {
    return {
      [EnComplainType.DUPLICATE_CHAPTER]: 'DUPLICATE_CHAPTER',
      [EnComplainType.LOST_CHAPTER_CONTENT]: 'LOST_CHAPTER_CONTENT',
      [EnComplainType.WRONG_PAGE_INDEX]: 'WRONG_PAGE_INDEX',
    }
  }
}
