import clsx from 'clsx'
import { Control, Controller, Path } from 'react-hook-form'
import Select2 from '../../Select2'
import { OptionType } from '../../Select2/types'
import { Error } from '../Error'
import { Hint } from '../Hint'
import { Label } from '../Label'

export const Select = <T extends Record<string, any>>(props: {
  control: Control<T, any>
  name: Path<T>
  title?: string
  isDisabled?: boolean
  isSearchable?: boolean
  isMulti?: boolean
  isClearable?: boolean
  minLength?: number
  placeholder?: string
  disableError?: boolean
  hint?: string

  loadOptions?: () => Promise<OptionType[]>
  onChange?: (value: string | string[] | null) => void
}) => {
  const { disableError = false, isSearchable = true } = props

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, formState: { errors, touchedFields } }) => {
        const isError = errors[props.name] !== undefined
        const isSuccess = isError ? false : touchedFields[props.name]
        const error = errors[props.name]
          ? String(errors[props.name]?.message)
          : ''

        return (
          <div className="grid grid-flow-row">
            {props.title && (
              <Label
                className={clsx('', {
                  '!text-success': isSuccess,
                  '!text-danger': isError,
                })}
                name={props.name}
                title={props.title}
              />
            )}

            <Select2
              refInstance={field.ref}
              id={`select-${props.name}`}
              isDisabled={props.isDisabled}
              minLength={props.minLength}
              isSearchable={isSearchable}
              isSuccess={isSuccess}
              isMulti={props.isMulti}
              isClearable={props.isClearable}
              isError={isError}
              value={field.value}
              placeholder={props.placeholder}
              loadOptions={props.loadOptions}
              onChange={(value: string | string[] | null) => {
                field.onChange(value)
                props.onChange && props.onChange(value)
              }}
              onBlur={field.onBlur}
            />
            {!disableError && (
              <div className="h-5 mt-0.5 grid">
                {error && <Error className="text-xs" message={error} />}
                {!error && <Hint message={props.hint} />}
              </div>
            )}
          </div>
        )
      }}
    />
  )
}
