import { t } from 'src/helpers/translate.helper'
import * as yup from 'yup'

yup.setLocale({
  number: {},
  string: {},
  tuple: {},
  mixed: {
    required: t('validation.required'),
    notNull: t('validation.notNull'),
    notType: t('validation.notType'),
    oneOf: (values: any) =>
      t('validation.oneOf: {values}', {
        values: values.resolved.join(', '),
      }),
  },
})

export default yup
