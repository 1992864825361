'use client'

import Link from 'next/link'
import { Fragment, memo } from 'react'
import { t } from 'src/helpers/translate.helper'

export const SubscriptionModal = memo(function SubscriptionModal(props: {
  onClose: () => void
}) {
  return (
    <Fragment>
      <h3 className="text-lg font-medium leading-6">
        {t('Purchase subscription')}
      </h3>

      <div className="mt-4">
        {t('You can buy subscription only on site ')}
        <Link
          className="text-primary font-medium"
          href="https://xn--80aaalhzvfe9b4a.xn--80asehdb/"
        >
          хентайманга.онлайн
        </Link>
        .
        <br />
        <b>{t('This subscription will be work for both sites')}</b>
      </div>

      <div className="grid grid-cols-2 gap-x-2 mt-6">
        <button
          onClick={() => props.onClose()}
          className="rounded-md ring-inset dark:text-red-500 text-red-600 dark:hover:text-black-1000 hover:text-white hover:bg-red-600 dark:hover:bg-red-500 dark:ring-red-500 ring-red-600 ring-1 outline-none"
        >
          {t('Close')}
        </button>

        <button
          onClick={() => {
            window.location.href =
              'https://xn--80aaalhzvfe9b4a.xn--80asehdb/subscription'
          }}
          className="rounded-md bg-primary font-medium px-3 py-2 text-sm text-white dark:text-black-1000"
        >
          {t('Proceed')}
        </button>
      </div>
    </Fragment>
  )
})

export default SubscriptionModal
